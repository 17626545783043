import {crypt, decrypt} from './crypt';

const TOKEN_KEY = 'qwer';
const USER_ID_KEY = 'zxcv';
const PERSON_ID_KEY = 'pxca';
const ROL_ID_KEY = 'uiop';
const ACTIONS_ID_KEY = 'acid';
const ACCESS_ID_KEY = 'aucdi';
const COMPETENCY_ID_KEY = 'ziop';
const COMPETENCY_NAME_KEY = 'niop';

export const putValue = (key, value) => {
    window.localStorage.setItem(key, crypt(value.toString()));
}

export const getValue = (key) => {
    let data = window.localStorage.getItem(key);
    if(data != null) return decrypt(data);
    else return data;
}

export const putObj = (key, value) => {
    window.localStorage.setItem(key, crypt(JSON.stringify(value)));
}

export const getObj = (key) => {
    let data = window.localStorage.getItem(key);
    if(data != null) return JSON.parse(decrypt(data));
    else return data;
}

export const cleanValue = (key) => {
    window.localStorage.removeItem(key);
}

export const cleanValues = () => {
    window.localStorage.clear();
}

export const putToken = (token) => {
    putValue(TOKEN_KEY, token);
}

export const getToken = () => {
    return getValue(TOKEN_KEY);
}

export const putActions = (data) => {
    putObj(ACTIONS_ID_KEY, data);
}

export const getActions = () => {
    return getObj(ACTIONS_ID_KEY);
}

export const putAccess = (data) => {
    putObj(ACCESS_ID_KEY, data);
}

export const getAccess = () => {
    return getObj(ACCESS_ID_KEY);
}

export const putCompetencyId = (value) => {
    putValue(COMPETENCY_ID_KEY, value);
}

export const getCompetencyId = () => {
    return getValue(COMPETENCY_ID_KEY);
}

export const putCompetencyName = (value) => {
    putValue(COMPETENCY_NAME_KEY, value);
}

export const getCompetencyName = () => {
    return getValue(COMPETENCY_NAME_KEY);
}

export const putUserId = (id) => {
    putValue(USER_ID_KEY, id);
}

export const getUserId = () => {
    return getValue(USER_ID_KEY);
}

export const putRol = (id) => {
    putValue(ROL_ID_KEY, id);
}

export const getRol = () => {
    return getValue(ROL_ID_KEY);
}

export const putPersonId = (id) => {
    putValue(PERSON_ID_KEY, id);
}

export const getPersonId = () => {
    return getValue(PERSON_ID_KEY);
}
import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/procedure-group';

export const findProcedureGroupById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllProcedureGroup = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createProcedureGroup = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editProcedureGroup = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllProcedureGroupPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const downloadProcedureGroupIndividualProceduresExcelByForm = async (form) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/student-sheet/form/${form}`, GET_OPTIONS);
}

export const downloadProcedureGroupProceduresCareerExcelByStudent = async (student) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/career/student/${student}`, GET_OPTIONS);
}
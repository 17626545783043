import React, { useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useActiveForms } from "./hook";
import SemestersMenu from "./semesters_menu";
import CreateForm from "./create_form";
import CardInfo from "./card_info";
import { getPersonId, getRol } from "../../../../../_utils/storage_handler";

function Info(){

    const hook = useActiveForms();

    const renderActiveForm = useCallback((teachers) => {
        const rol = getRol();
        if(rol === 'ADMI') return true;

        return teachers.includes(getPersonId());
    }, [])

    return(
        <Container fluid>
            <Row>
                <Col lg={2}>
                    <SemestersMenu
                        hook={hook}
                    />
                </Col>
                <Col lg={10}>
                    {
                        hook.loading ?
                            <div className="flex-col flex-center" style={{ margin: '30px 0px' }}>
                                <div className="vp-spinner"></div>
                            </div>
                        :
                        hook.data === null ?
                        getRol() === 'ADMI' ?
                        <CreateForm
                            semesterCod={hook.selected}
                            reload={() => hook.loadData(hook.selected)}
                        />
                        :
                        <>No estas autorizado</>
                        :
                        renderActiveForm(hook.data?.teachers ?? []) ?
                        <CardInfo
                            data={hook.data}
                            reload={() => hook.loadData(hook.selected)}
                        />
                        :
                        <>No estas autorizado</>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default Info;
export const validateProcedureGroup = (body) => {
    const { name, min_procedures } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(min_procedures === null || min_procedures === '') throw new Error("Procedimientos mínimos vacíos");
}

export const transformEntityProcedureGroup = (entity) => {
    let newEnt = {...entity};
    return newEnt;
}

export const buildEmptyProcedureGroup = () => {
    return{
        name: '',
        min_procedures: 0,
    };
}
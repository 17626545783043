import { 
    findAllProcedureGroupPaged,
    findProcedureGroupById, 
    findAllProcedureGroup,
    downloadProcedureGroupIndividualProceduresExcelByForm,
    downloadProcedureGroupProceduresCareerExcelByStudent
} from "../../_services/procedure_group.service";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const findAllProcedureGroupPagedEvent = async (page, limit = 8) => {
    return await findAllProcedureGroupPaged(page, limit);
}

export const findProcedureGroupByIdEvent = async (id) => {
    return await findProcedureGroupById(id);
}

export const findAllProcedureGroupEvent = async () => {
    return await findAllProcedureGroup();
}

export const downloadProcedureGroupIndividualProceduresExcelByFormEvent = async (form) => {
    const json = await downloadProcedureGroupIndividualProceduresExcelByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_individual_${new Date().toISOString()}`);
    }
}

export const downloadProcedureGroupProceduresCareerExcelByStudentEvent = async (student) => {
    const json = await downloadProcedureGroupProceduresCareerExcelByStudent(student);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_carrera_${new Date().toISOString()}`);
    }
}

const MAX_EVALUATION = 5;

export const evalToPoint = (evaluation, maxPoint) => {
    return evaluation * maxPoint / MAX_EVALUATION;
}

export const pointToEval = (point, maxPoint) => {
    const result = Number(point) * Number(MAX_EVALUATION) / Number(maxPoint);

    return hasDecimalZeros(result, 2) ? result.toFixed(0) : result;
}

export const additionalEvalToPoint = (evaluation, maxPoint, percent) => {
    const evaluationN = Number(evaluation);
    const percentN = Number(percent);
    const maxPointN = (maxPoint);
    return (evaluationN * maxPointN / MAX_EVALUATION) * (percentN / 100);
}

export const pointToAdditionalEval = (point, maxPoint, percent) => {
    const pointN = Number(point);
    const percentN = Number(percent);
    const maxPointN = (maxPoint);
    const result = Number((pointN * 100 / percentN) * MAX_EVALUATION / maxPointN);
    
    return hasDecimalZeros(result, 2) ? result.toFixed(0) : result;
}

export function hasDecimalZeros(number, decimalZeros) {
    const numberString = number.toString();

    if (numberString.includes('.')) {
        const decimalPart = numberString.split('.')[1];
        const zerosCount = decimalPart.match(/0/g)?.length || 0;
        
        return zerosCount > decimalZeros;
    }
    
    return false;
}
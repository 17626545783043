import { 
    findAllCompetencyGroupPaged, 
    findCompetencyGroupById, 
    findAllCompetencyGroup, 
    findAllCompetencyGroupPagedLikeName,
    downloadCompetencyGroupProceduresExcelByForm,
    downloadCompetencyGroupIndividualProceduresExcelByForm,
    downloadCompetencyGroupsProceduresExcelByForms,
    downloadCompetencyGroupProceduresCareerExcelByStudent
} from "../../_services/competency_group.service";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const findAllCompetencyGroupPagedEvent = async (page, limit = 8) => {
    return await findAllCompetencyGroupPaged(page, limit);
}

export const findCompetencyGroupByIdEvent = async (id) => {
    return await findCompetencyGroupById(id);
}

export const findAllCompetencyGroupEvent = async () => {
    return await findAllCompetencyGroup();
}

export const findAllCompetencyGroupPagedLikeNameEvent = async (page, limit = 8, name) => {
    return await findAllCompetencyGroupPagedLikeName(page, limit, name);
}

export const downloadCompetencyGroupProceduresExcelByFormEvent = async (form) => {
    const json = await downloadCompetencyGroupProceduresExcelByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_${new Date().toISOString()}`);
    }
}

export const downloadCompetencyGroupIndividualProceduresExcelByFormEvent = async (form) => {
    const json = await downloadCompetencyGroupIndividualProceduresExcelByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_individual_${new Date().toISOString()}`);
    }
}

export const downloadCompetencyGroupProceduresCareerExcelByStudentEvent = async (student) => {
    const json = await downloadCompetencyGroupProceduresCareerExcelByStudent(student);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_carrera_${new Date().toISOString()}`);
    }
}

export const downloadCompetencyGroupsProceduresExcelByFormsEvent = async (body) => {
    const json = await downloadCompetencyGroupsProceduresExcelByForms(body);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidados_${new Date().toISOString()}`);
    }
}
export const validateForm = (body) => {
    const { semester, students, teachers } = body;
    console.log(teachers);
    if(semester === null || semester === '') throw new Error("Semestre vacío");
    if(teachers === null || teachers === undefined || teachers?.length === 0) throw new Error("Se necesita agregar docentes");
    //if(person === null || person === '') throw new Error("Tutor 1 vacío");
    //if(personEndo === null || personEndo === '') throw new Error("Tutor 3 vacío");
    //if(personRehab === null || personRehab === '') throw new Error("Tutor 2 vacío");
    if(students){
        if(students.length === 0) throw new Error("Sin estudiantes");
    }
}

export const transformEntityForm = (entity) => {
    let newEnt = {...entity};
    newEnt.person = newEnt.person?.uuid;
    newEnt.personEndo = newEnt.personEndo?.uuid;
    newEnt.personRehab = newEnt.personRehab?.uuid;
    return newEnt;
}

export const buildEmptyForm = (semester) => {
    return{
        person: '',
        personEndo: '',
        personRehab: '',
        teachers: [],
        semester,
        students: [],
        copy_form: '',
        copy_name: '',
        copy_template: '',
    };
}
import { useStudentFormByForm } from "../../_hooks/student_form/use-student-form-by-form.hook";
import ObjectSelect from "./object_select";

export default function FormStudentSelect({ form, value, onChange }) {

    const data = useStudentFormByForm(form);

    const buildOptions = () => {
        let options = data.data.map( d => ({ value: d.student?.uuid, label: `${d.student?.name}` }) );
        options.unshift({ value: '', label: 'Selecciona...' });
        return options;
    }

    return(
        <ObjectSelect 
            value={value}
            onChange={onChange}
            options={buildOptions()}
        />
    );
}
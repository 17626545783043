import React from "react";
import { useQuery } from "../../../../../_utils/url_query_hook";
import TermsInfo from "./term_info";
import { Nav } from "react-bootstrap";
import { deleteEvaluationByFormAndActivityAndTerm } from "../../../../../_services/evaluation.service";
import { alertError } from "../../../../../_utils/alerts";
import { useAcademicPartAllWithFormByIdAndTermTotal } from "../../../../../_hooks/academic_part/useAcademicPartAllWithFormByIdTermTotal.hook";

function EditFormTabs(){

    const [key, setKey] = React.useState('1');
    const query = useQuery();
    const uuid = query.get("id");
    const semester = query.get("semester");
    const parent_semester = query.get("parentsemester");
    const formHook = useAcademicPartAllWithFormByIdAndTermTotal(uuid, key);

    const changeTab = (k) => {
        setKey(k);        
    }
    const deleteAndLoad = (form, activity, term) => {
        deleteEvaluationByFormAndActivityAndTerm(form, activity, term)
        .then(data => {
            formHook.loadData(uuid, key);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    if(formHook.data) 
    return (
        <div>
            <div>
                <Nav variant="tabs" defaultActiveKey="1">                
                    <Nav.Item>
                        <Nav.Link onClick={() => changeTab('1')} eventKey="1">Corte 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => changeTab('2')} eventKey="2">Corte 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => changeTab('3')} eventKey="3">Corte 3</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div style={{ marginTop: '20px' }}>
                <TermsInfo
                    form={uuid}
                    term={key}
                    data={formHook.data? formHook.data : []}
                    total={formHook.total? formHook.total : 0}
                    semester={semester}
                    parent_semester={parent_semester}
                    deleteAndLoad={deleteAndLoad}
                    loadFunc={() => formHook.loadData(uuid, key)}
                />
            </div>
        </div>
    );
    else
    return (
        <></>
    );
}

export default EditFormTabs;
import { 
    editProcedureGroup, 
    createProcedureGroup 
} from "../../_services/procedure_group.service";
import { validateProcedureGroup } from "./model";

export const createProcedureGroupEvent = async (body) => {
    validateProcedureGroup(body);
    return await createProcedureGroup(body);
}

export const editProcedureGroupEvent = async (id, body) => {
    validateProcedureGroup(body);
    return await editProcedureGroup(id, body);
}

import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { 
    buildEmptyProcedureGroup, 
    transformEntityProcedureGroup 
} from "../../_events/procedure_group/model";
import { findProcedureGroupByIdEvent } from "../../_events/procedure_group/find.event";
import { 
    createProcedureGroupEvent, 
    editProcedureGroupEvent 
} from "../../_events/procedure_group/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useCreateEditProcedureGroup = () => {

    const query = useQuery();
    const uuid = query.get("id");

    const [ entity, setEntity ] = React.useState(buildEmptyProcedureGroup());

    const create = () => {
        createProcedureGroupEvent(entity)
        .then(_ => {
            alertSuccess("Guardado");
            setEntity(buildEmptyProcedureGroup());
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editProcedureGroupEvent(uuid, entity)
        .then(_ => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findProcedureGroupByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityProcedureGroup(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit };
}
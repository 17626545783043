import { editCompetencyGroup, createCompetencyGroup, changeStateById, changeMinProceduresById } from "../../_services/competency_group.service";
import { validateCompetencyGroup } from "./model";

export const createCompetencyGroupEvent = async (body) => {
    validateCompetencyGroup(body);
    if(body.min_procedures === '') body.min_procedures = 0;
    return await createCompetencyGroup(body);
}

export const editCompetencyGroupEvent = async (id, body) => {
    validateCompetencyGroup(body);
    if(body.min_procedures === '') body.min_procedures = 0;
    return await editCompetencyGroup(id, body);
}

export const changeStateByIdCompetencyGroupEvent = async (id, state) => {
    return await changeStateById(id, state);
}

export const changeMinProceduresByIdEvent = async (id, procedures) => {
    if(procedures === '') throw new Error('Procedimientos vacíos');
    return await changeMinProceduresById(id, procedures);
}
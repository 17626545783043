import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/form';

export const findFormById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findFormByIdWithAll = async (id) => {
    return await handleFetch(`${BASE_PATH}/id-all/${id}`, GET_OPTIONS);
}

export const findActiveFormBySemester = async (id) => {
    return await handleFetch(`${BASE_PATH}/active/semester/${id}`, GET_OPTIONS);
}

export const findFinishFormBySemester = async (id) => {
    return await handleFetch(`${BASE_PATH}/finish/semester/${id}`, GET_OPTIONS);
}

export const findAllForm = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createForm = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const createFormWithStudents = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-with-students`, POST_OPTIONS, body);
}

export const createFormWithStudentsCopy = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-with-students-copy`, POST_OPTIONS, body);
}

export const createFormWithStudentsCopyTemplate = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-with-students-copy-template`, POST_OPTIONS, body);
}

export const editForm = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const inactiveForm = async (id) => {
    return await handleFetch(`${BASE_PATH}/inactive/${id}`, PUT_OPTIONS);
}

export const activeForm = async (id) => {
    return await handleFetch(`${BASE_PATH}/active/${id}`, PUT_OPTIONS);
}

export const finishForm = async (id) => {
    return await handleFetch(`${BASE_PATH}/finish/${id}`, PUT_OPTIONS);
}

export const findAllFormPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllActiveFormNotId = async (id) => {
    return await handleFetch(`${BASE_PATH}/not/form/${id}`, GET_OPTIONS);
}
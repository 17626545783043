import React from "react";
import { 
    findAllProcedureGroupPagedEvent,
} from "../../_events/procedure_group/find.event";
import { alertError } from "../../_utils/alerts";

export const useProcedureGroupsPaged = ({ page = 0, limit = 8 }) => {

    const [data, setData] = React.useState([]);

    const loadData = (p, l) => {
        findAllProcedureGroupPagedEvent(p, l)
        .then(json => {
            setData(json.paged);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(page, limit);
    }, [page, limit]);

    return { data, loadData };
}
import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link } from "react-router-dom";
import { path_modules } from "../../../app_modules";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ObjectSelect from "../../../../widgets/select/object_select";
import { useCompetencyCreateEdit } from "../../../../_hooks/competency/useCompetencyCreateEdit.hook";
import { useCompetencyGroupAll } from "../../../../_hooks/competency_group/useAllCompetencyGroup.hook";
import { useProcedureGroups } from "../../../../_hooks/procedure_group/use-procedure-groups.hook";

function CompetencyForm(){

    const creationHook = useCompetencyCreateEdit();

    const groups = useCompetencyGroupAll();
    const procedureGroups = useProcedureGroups();

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.parametrization.children.competency.fullPath}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                    <span className="vp-title">Competencia</span>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='form-label'>Nombre</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity?.name }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.name = e.target.value;
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                            <div className='form-label'>Conteo de procedimientos</div>
                            <Form.Check
                                type="switch"
                                label={creationHook.entity.needs_procedures === 1 ? 'Conteo activado' : 'Conteo desactivado'}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity}
                                    newEnt.needs_procedures = e.target.checked === true ? 1 : 0;
                                    creationHook.setEntity(newEnt);
                                }}
                                checked={ creationHook.entity.needs_procedures === 1 }
                            />

                        </Col>
                        <Col lg={6}>
                            <div className='form-label'>Grupo de competencia</div>
                            <ObjectSelect
                                value={creationHook.entity?.competency_group}
                                options={groups.data.map( d => ({ value: d.uuid, label: d.name }))}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.competency_group = e;
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                            <div className='form-label'>Grupo de procedimiento</div>
                            <ObjectSelect
                                value={creationHook.entity?.procedure_group}
                                options={[{value: null, label: 'N.A.'}].concat(procedureGroups.data.map( d => ({ value: d.uuid, label: d.name })))}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.procedure_group = e;
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}></Col>
                        <Col lg={4}>
                            <Button onClick={ creationHook.uuid ? () => creationHook.edit() : () => creationHook.create() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                { creationHook.uuid ? 'Editar' : 'Crear' }
                            </Button>
                        </Col>
                        <Col lg={4}></Col>
                    </Row>
                </Container>
            </div>
        </MainContent>
    );
}

export default CompetencyForm;
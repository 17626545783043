import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormCreateEdit } from "../../../../../_hooks/form/useFormCreateEdit.hook";
import { useAllTeachers } from "../../../../../_hooks/person/useAllTeachers.hook";
import StudentsTable from "./students_table";
import FormsModal from "./forms_modal";
import TemplateModal from "./template_modal";
import ObjectMultiSelect from "../../../../../widgets/select/object_multi_select";

function CreateForm({ semesterCod, reload }){

    const creationHook = useFormCreateEdit(semesterCod);
    const teachersHook = useAllTeachers();

    return(
        <Container>
            <Row>
                <Col lg={12}>
                    <span style={{ fontWeight: 'bold' }}>Crear planilla activa</span>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className='form-label'>Docentes</div>
                                <ObjectMultiSelect 
                                    value={creationHook.entity?.teachers ?? []}
                                    onChange={(e) => {
                                        creationHook.setEntity({
                                            ...creationHook.entity,
                                            teachers: e
                                        });
                                    }}
                                    options={teachersHook.data.map( d => ({ value: d.uuid, label: d.name }))}
                                />
                                <hr />
                                <div className='form-label' style={{ fontWeight: 'bold' }}>Copiar actividades de planillas</div>
                                <FormsModal hook = {creationHook} />
                                <div className='form-label' style={{ fontWeight: 'bold' }}>Copiar template</div>
                                <TemplateModal hook = {creationHook} />
                                {
                                    (creationHook.entity.copy_form === null || creationHook.entity.copy_form === '') && (creationHook.entity.copy_template === null || creationHook.entity.copy_template === '')
                                    ?
                                    <div className='form-label'>Sin copia</div>
                                    :
                                    <div className='form-label' style={{ color: 'green', fontWeight: 'bold' }}>Con copia de ({creationHook.entity.copy_name})</div>
                                }
                            </Col>
                            <Col lg={6}>
                            <div className='form-label' style={{ fontWeight: 'bold' }}>Estudiantes</div>
                            <StudentsTable
                                students={creationHook.entity.students}
                                selectStudent={(checked, uuid) => {
                                    let newEnt = {...creationHook.entity};
                                    if(checked) newEnt.students.push(uuid);
                                    else {
                                        let index = newEnt.students.findIndex(s => s === uuid);
                                        if(index !== -1) newEnt.students.splice(index, 1);
                                    }
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col lg={4}>
                            </Col>
                            <Col lg={4}>
                                <Button style={{ width: '100%' }} size="sm" variant="success"
                                    onClick={() => {
                                        creationHook.createWithStudents(semesterCod, () => {
                                            reload();
                                        });
                                    }}
                                >
                                    Crear planilla
                                </Button>
                            </Col>
                            <Col lg={4}>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default CreateForm;
import React from "react";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { buildEmptyFormTemplate } from "../../_events/form_template/model";
import { createFormTemplateFromFormEvent } from "../../_events/form_template/create.event";

export const useCreateFormTemplateFromForm = (formId) => {

    const [ entity, setEntity ] = React.useState(buildEmptyFormTemplate(formId));
    const [ isLoading, setIsLoading ] = React.useState(false);

    const create = () => {
        setIsLoading(true);
        createFormTemplateFromFormEvent(entity)
        .then(_ => {
            alertSuccess("Guardado");
            setEntity(buildEmptyFormTemplate(formId));
            setIsLoading(false);
        })
        .catch(err => {
            setIsLoading(false);
            alertError(err.message);
        });      
    }

    return { isLoading, entity, setEntity, create };
}
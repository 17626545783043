import { Button } from "react-bootstrap";
import { useState } from "react";
import { Modal } from "antd";
import { useCreateFormTemplateFromForm } from "../../../../../_hooks/form_template/use-create-form-template-from-form.hook";

export default function CreateTemplateModal({ formId }) {

    const createHook = useCreateFormTemplateFromForm(formId);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <div>
            <Button 
                size="sm"
                onClick={() => {
                    showModal();
                }}
                variant="success"
            >
                Crear template
            </Button>
            <Modal title="Crear template" open={isModalOpen} footer={<></>} onCancel={handleCancel}>
                <div className='form-label'>Nombre de template</div>
                <input 
                    className='vp-input' 
                    type='text'
                    value={ createHook.entity?.name }
                    onChange={(e) => {
                        createHook.setEntity({
                            ...createHook.entity,
                            name: e.target.value
                        });
                    }}
                />
                {
                    createHook.isLoading ? 
                    <div className="vp-spinner"></div>
                    :
                    <Button 
                        onClick={() => {
                            createHook.create();
                        }}
                        style={{ marginTop: '10px' }} 
                        variant="success">
                        Crear
                    </Button>
                }
            </Modal>
        </div>
    );
}
import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/form-template';

export const findFormTemplateById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findFormTemplateBySemester = async (id) => {
    return await handleFetch(`${BASE_PATH}/semester/${id}`, GET_OPTIONS);
}

export const findAllFormTemplate = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createFormTemplate = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editFormTemplate = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllFormTemplatePaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const createFormTemplateFromForm = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-from-form`, POST_OPTIONS, body);
}
export const validateCompetency = (body) => {
    const { name, competency_group } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(competency_group === null || competency_group === '') throw new Error("Grupo de competencia vacío");

}

export const validateDuplicationCompetency = (body) => {
    const { name, uuid } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(uuid === null || uuid === '') throw new Error("Competencia a duplicar vacía");

}

export const transformEntityCompetency = (entity) => {
    let newEnt = {...entity};
    newEnt.competency_group = newEnt.competency_group?.uuid;
    newEnt.procedure_group = newEnt.procedure_group?.uuid;
    return newEnt;
}

export const buildEmptyCompetency = () => {
    return{
        name: '',
        state: 1,
        needs_procedures: 0,
        competency_group: '',
        procedure_group: null,
    };
}

export const buildEmptyCompetencyDuplication = () => {
    return{
        uuid: '',
        name: '',
    };
}
export const validateFormTemplate = (body) => {
    const { name, copy_form } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(copy_form === null || copy_form === '') throw new Error("Planilla base vacía");

}

export const transformEntityFormTemplate = (entity) => {
    let newEnt = {...entity};
    if(newEnt.semester) newEnt.semester = newEnt.semester.cod;
    return newEnt;
}

export const buildEmptyFormTemplate = (copy_form = '') => {
    return{
        date_created: new Date(),
        name: '',
        copy_form
    };
}
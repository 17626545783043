export const validateSemester = (body) => {
    const { name, cod } = body;
    if(cod === null || cod === '') throw new Error("Código vacío");
    if(name === null || name === '') throw new Error("Nombre vacío");
}

export const transformEntitySemester = (entity) => {
    let newEnt = {...entity};
    return newEnt;
}

export const buildEmptySemester = () => {
    return{
        cod: '',
        name: '',
        parent_semester: null,
    };
}
import React from "react";
import { findAllStudentFormByFormEvent } from "../../_events/student_form/find.event";
import { alertError } from "../../_utils/alerts";

export const useStudentFormByForm = (form) => {

    const [data, setData] = React.useState([]);

    const loadData = (f) => {
        findAllStudentFormByFormEvent(f)
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(form);
    }, [form]);

    return { data, loadData };
}